// php
import Vue from 'vue'
import * as API from "./index.js";
import {
  buildParam
} from '@/utils/index'
const BaseApi = process.env.VUE_APP_BASE_API
const SysApi = process.env.VUE_APP_SYS_API
const WxApi = process.env.VUE_APP_WX_API
const WxURL = process.env.NODE_ENV === "production" ? "http://sandbox01.hejiahejiazhuang.com" : WxApi
export default {
  //根据code获取userId
  getUserId: (params) => API.GET(`${SysApi}/tools/qywx/auth/getUserInfoByCode/public`, params),
  //登录
  login: (params) => API.POST(`${SysApi}/author/oauth/token`, params),
  //获取验证码
  getAutoCode: () => API.GET(`${SysApi}/backend/validate/img/public`),
  //退出登录
  logOut: () => API.POST(`${SysApi}/author/oauth/logout`),

  //根据字典查询
  getDictionariesList: (params) => {
    params.select = 'dist_info'
    buildParam(params)
    return API.POST(`${SysApi}/backend/dict/es/r01`, params)
  },
  //字典查询(排序)
  dictionary: (params) => {
    buildParam(params)
    return API.POST(`${SysApi}/backend/dictItem/r02`, params)

  },
  // 获取省市区
  getArea: (params) => {
    buildParam(params)
    return API.POST(`${SysApi}/tools/area/p01`, params)
  },

  //签单模块
  //获取客户列表
  getClientList: (params, ext) => {
    buildParam(params)
    params.ext = ext
    return API.POST(`${BaseApi}/customer/consumerSign/appList`, params)
  },
  // 初始化获取夜间签单菜单列表
  getNightList: (params, ext) => {
    buildParam(params)
    params.ext = ext
    return API.POST(`${BaseApi}/customer/consumerSign/atNightList`, params)
  },
  //丢单
  loseBill: (params) => API.POST(`${BaseApi}/customer/consumerSign/lostOrder`, params),
  //签单
  signBill: (params) => API.POST(`${BaseApi}/customer/consumerSign/signature`, params),
  //夜间签单
  signBill1: (params) => API.POST(`${BaseApi}/customer/consumerSign/atNightSign`, params),
  //跟进
  followAdd: (params) => API.POST(`${BaseApi}/customer/consumerAdvance/add`, params),
  //完成跟单
  overFollow: (id) => API.POST(`${BaseApi}/customer/consumerService/complete/${id}`),

  //获取客户信息
  getClientInfo: (id) => API.POST(`${BaseApi}/customer/consumerInfo/details/${id}`),
  //获取客户资料
  getClientData: (id) => API.POST(`${BaseApi}/customer/consumerInfo/information/${id}`),
  //编辑客户资料
  editClientData: (params) => API.POST(`${BaseApi}/customer/consumerInfo/updateInformation`, params),
  //获取跟进历史
  getFollowList: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/customer/consumerAdvance/queryByConsumerId`, params)
  },
  //获取图片历史
  getphotosList: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/customer/consumerFile/list`, params)
  },
  //获取联系人列表
  getLinkmanList: (id) => API.GET(`${BaseApi}/customer/consumerContact/queryByConsumerId/${id}`),
  //新增联系人
  addLinkman: (params) => API.POST(`${BaseApi}/customer/consumerContact/add`, params),
  //删除联系人
  delLinkman: (id) => API.POST(`${BaseApi}/customer/consumerContact/cancellation/${id}`),
  //保存图片/录音
  saveFiles: (params) => API.POST(`${BaseApi}/customer/consumerFile/add`, params),

  //获取录音列表
  getRecordList: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/customer/consumerFile/list`, params)
  },
  // 扫码查询用户信息
  getScanInfomation: (id) => API.GET(`${BaseApi}/customer/consumerSign/getCustomerSignById?id=${id}`),
  // 完成跟单
  documentaried: (id) => API.POST(`${BaseApi}/customer/consumerService/complete/${id}`),
  // 领取客户
  claimcust: (id) => API.GET(`${BaseApi}/customer/consumerSign/collectingCustomer?id=${id}`),
  // 修改录音名称
  editRadioName: (params) => API.POST(`${BaseApi}/customer/consumerFile/editFileInfo`, params),
  // 下载录音
  downaudio: (params) => API.POST(`${SysApi}/tools/oss/ali/downOSSFile?fileName=${params.fileName}&dir=oss`, { responseType: 'blob' }),



  //客户管理话务
  //客户列表
  getClientSList: (params, ext) => {
    buildParam(params)
    params.ext = ext
    return API.POST(`${BaseApi}/customer/consumerService/appList`, params)
  },
  //到店
  arriveStore: (params) => API.POST(`${BaseApi}/customer/consumerService/arrival`, params),
  //二次到店
  arriveStoreAgain: (params) => API.POST(`${BaseApi}/customer/consumerService/againArrive`, params),
  //签单员列表（空闲签单员：手中没有客户）
  allotSignList: (params) => API.POST(`${BaseApi}/customer/consumerService/signUser`, params),
  //分配签单/变更
  allotSign: (params) => API.POST(`${BaseApi}/customer/consumerService/distributeSignUser`, params),
  //离店
  leaveStore: (id) => API.POST(`${BaseApi}/customer/consumerService/leave` + `/${id}`),
  //话务列表客户详情
  getClientInfoC: (id) => API.GET(`${BaseApi}/customer/consumerInfo/queryById/${id}`),
  //客服客户编辑
  editClientC: (params) => API.POST(`${BaseApi}/customer/consumerInfo/edit`, params),
  //客户撤回
  allotRevocation: (id) => API.GET(`${BaseApi}/customer/consumerService/withdraw/${id}`),
  //获取企业微信config信息
  getWxConfig: (params) => API.GET(`${SysApi}/tools/qywx/auth/getJsApiSign`, params),


  // getWxConfig: (params) => API.GET(`${WxURL}/jssdk/sample.php`,params),


  //获取擂台榜列表
  getRingList: (params) => API.GET(`/sign/ring/ring/list`, params),

  // 分包单列表
  getSubpackageList: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/sellDivide/list`, params)
  },
  // 分保单 根据id查询
  getSubpackageDetail: (id) => {
    return API.GET(`${BaseApi}/decorate/sellDivide/queryById/${id}`)
  },
  // 分保单详情 是否已读
  editSubpackageDetailIsLock: (id) => {
    return API.GET(`${BaseApi}/decorate/sellDivide/upIsLock/${id}`)
  },
  // 分保单详情 图片上传
  addSubpackageImgDetail: (params) => {
    return API.POST(`${BaseApi}/decorate/sellDivideImg/add`, params)
  },
  // 分保单详情 获取图片上传详情
  getSubpackageImgDetail: (id) => {
    return API.GET(`${BaseApi}/decorate/sellDivideImg/queryById/${id}`)
  },
  // 验收分包单
  checkSubpackaged: (params) => {
    return API.POST(`${BaseApi}/decorate/sellDivide/careful`, params)
  },

  //深化模块
  //施工进度类型列表
  getProjectNodeList: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/projectBaseNode/list`, params)
  },
  //工程计划列表
  projectInfoNodeInfoById: (params) => {
    return API.POST(`${BaseApi}/decorate/projectInfo/projectInfoNodeInfoById`, params)
  },
  //工序库列表
  getProjectCustomStageList: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/customStage/list`, params)
  },
  //项目经理列表
  getProjectManagerList: (params) => {
    return API.POST(`${BaseApi}/decorate/projectInfo/manager`, params)
  },
  //在建项目列表
  getPatrolList: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/projectInfo/listPro`, params)
  },
  //在建项目 巡检记录
  getPatrolRecordList: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/projectInspection/list`, params)
  },
  //在建项目 巡检记录 新
  getPatrolRecordListNew: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/projectInspection/listNew`, params)
  },
  //在建项目 巡检记录详情
  getPatrolRecordDetails: (params) => API.POST(`${BaseApi}/decorate/projectInspectionItem/listImg`, params),
  //在建项目 巡检阶段列表
  getProjectInspectList: (params) => API.POST(`${BaseApi}/decorate/projectInspection/list`, params),
  //获取巡检阶段树形列表
  getProjectNodeTree: (params) => API.POST(`${BaseApi}/decorate/projectContent/list`, params),
  //在建项目 巡检整改-新增巡检记录
  addPatrolRecord: (params) => API.POST(`${BaseApi}/decorate/projectInspection/addPro`, params),
  //在建项目 巡检整改-新增巡检记录 新
  addPatrolRecordNew: (params) => API.POST(`${BaseApi}/decorate/projectInspection/add`, params),
  //巡检整改（现存隐患、解决隐患、符合标准）
  getProjectStatesList: (params) => {
    let obj = {
      order: JSON.stringify(params)
    }
    return API.POST(`${BaseApi}/decorate/projectInspectionItem/listDetil`, obj)
  },
  //查询套餐类型
  getPackageType: (id) => API.GET(`${BaseApi}/decorate/projectInfo/budget/${id}`),
  //获取跟单人员
  getFollowPeopleList: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/orderOperator/list`, params)
  },
  //在建项目 巡检整改-现存隐患-待审核列表
  getExtantDangerAuditList: (params) => API.GET(`/depth/depthCheckContent/underReview`, params),
  //在建项目 巡检整改-现存隐患-整改中列表
  getExtantDangerAmendList: (params) => API.GET(`/depth/depthCheckContent/unQualified`, params),
  //在建项目 巡检整改-现存隐患-整改记录审核
  dangerAudit: (params) => API.POST(`${BaseApi}/decorate/projectInspectionReform/check`, params),
  //在建项目 巡检整改-现存隐患-整改记录列表
  getAmendRecordList: (id) => API.GET(`${BaseApi}/decorate/projectInspectionReform/reformById/${id}`),
  //在建项目 巡检整改-解决隐患列表
  getResolvedList: (params) => API.GET(`/depth/depthCheckContent/resolved`, params),
  //在建项目 巡检整改-符合标准列表
  getStandardList: (params) => API.GET(`/depth/depthCheckContent/qualified`, params),
  //在建项目 工程进度列表
  getProjectPlanList: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/projectProcessNode/list`, params)
  },
  //在建项目 工程进度-上传工程完成图片
  projectPlanFinish: (params) => API.POST(`${BaseApi}/decorate/projectProcessImg/addList`, params),
  //在建项目 工程进度-查看
  checkProjectPlan: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/projectProcessImg/listPro`, params)
  },
  //在建项目 工程进度-查看 删除
  checkProjectPlanDelte: (id) => API.DELETE(`${BaseApi}/decorate/projectProcessImg/deleteImg?id=${id}`),
  //在建项目 工程进度-查看 查看记录
  checkProjectPlanRecord: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/projectProcessImgRecord/pageList`, params)
  },
  // 销售单列表
  salesList: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/sellOrder/list`, params)
  },
  // 销售单各种状态集合
  countData: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/sellOrder/count`, params)
  },
  // 销售单删除
  saleDelete: (params) => {
    return API.POST(`${BaseApi}/decorate/sellOrder/delete`, params)
  },
  // 销售单详情表格
  queryTableSale: (id) => {
    return API.GET(`${BaseApi}/decorate/sellOrder/queryItemListBySellOrderId?id=${id}`)
  },
  // 销售单详情提交
  submitSale: (params) => {
    return API.POST(`${BaseApi}/decorate/sellOrder/sellOrderSubmit`, params)
  },
  // 销售单下单
  palceSale: (params) => {
    return API.POST(`${BaseApi}/decorate/sellOrder/placeOrder`, params)
  },
  // 查询销售单详情
  SearchDetails: (id) => {
    return API.GET(`${BaseApi}/decorate/sellOrder/queryById?id=${id}`)
  },
  // 查询工程状态
  queryProjectStatus: (params) => {
    buildParam(params)
    return API.POST(`${SysApi}/backend/dictItem/r01`, params)
  },

  //在建工程  评分
  engineeringGrade: (params) => API.POST(`${BaseApi}/decorate/projectInfo/edit`, params),
  //在建项目 整改上传
  amendUp: (params) => API.POST(`${BaseApi}/decorate/projectInspectionReform/addrectify`, params),

  //上传图片
  upFile: (params) => API.POST(`${SysApi}/tools/oss/ali/upload`, params),
  //量房信息
  getRoomInfoList: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/orderRoom/list`, params)
  },
  //设计图纸
  getDesignDrawingList: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/orderDesign/list`, params)
  },
  //预算单
  getBudgetSheetList: (id) => API.GET(`${BaseApi}/decorate/budgetItem/r01/${id}`),
  //选材单
  getMaterialSheetList: (id) =>  API.GET(`${BaseApi}/decorate/budgetItem/r03/${id}`),
  //文档管理
  getFileManageList: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/orderFile/list`, params)
  },
  //合同管理
  getContractManageList: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/orderContract/list`, params)
  },
  

  // 辅材申领
  //获取辅材申领列表
  getMaterialsBills: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/materialApply/list`, params)
  },
  //获取辅材类别
  getMaterialsClass: (params) => API.POST(`${BaseApi}/decorate/productClass/treeList3`, params),
  //获取辅材列表
  getMaterialsList: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/productItem/pageList`, params)
  },
  //申领提交
  submitMaterials: (params) => API.POST(`${BaseApi}/decorate/materialApply/addPro`, params),
  //申领确认
  receiveSure: (params) => API.POST(`${BaseApi}/decorate/materialApply/edit`, params),
  //申领撤销
  cancelMaterials: (params) => API.POST(`${BaseApi}/decorate/materialApply/cancelMaterials`, params),
  //申领订单详情
  getMaterialsDetails: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/materialApplyItem/list`, params)
  },
  //辅材单详情
  getMaterialApplyDetails: (id) => API.GET(`${BaseApi}/decorate/materialApply/getMaterialApply/${id}`),

  //仓储
  //获取仓储列表
  getStorageList: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/materialApply/list01`, params)
  },
  //改变订单状态
  changeState: (params) => API.POST(`${BaseApi}/decorate/materialApply/edit`, params),

  //审批详情
  approvalDetails: (id) => API.POST(`${BaseApi}/decorate/orderInfo/examineParticulars?id=${id}`),
  //审批详情3
  approvalDetails3: (params) => {
    console.log("🚀 ~ params:", params)
    const { orderId, financePlanId } = params
    return API.GET(`${BaseApi}/decorate/sellOrder/userNoun?orderId=${orderId}&financePlanId=${financePlanId}`)
  },
  //审批内容
  approvalContent: (params) => API.POST(`${SysApi}/flow/processTasking/getApprovalInfo`, params),

  //审批
  approvalSubmit: (params) => API.POST(`${SysApi}/flow/processTask/complete`, params),

  // 查询下一步审批人
  checkPeo: (params) => API.POST(`${SysApi}/flow/process/selectNextNodeInfo`, params),

  //手动消息查看详情
  messageInfo: (params) => API.POST(`${BaseApi}/decorate/messageContent/content`, params),
  //手动消息已读
  messageRead: (params) => API.POST(`${BaseApi}/decorate/messagePerson/add`, params),

  //查询当前账号部门信息
  getDepart: (id) => API.GET(`${BaseApi}/customer/consumerSign/getDeptByUserId?userid=${id}`),

    // =========================================消息通知=================================
    // 缴费审核--详情
    supplierPage: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}/decorate/financeDetail/examineList`, params)
    },
    // 缴费审核--通过未通过
    payExamine: (params) => API.POST(`${BaseApi}/decorate/financeDetail/examine`, params),
    // 确认单确认退详情接口---自理销售
    orderDetailList: (id) => API.GET(`${BaseApi}/decorate/sellProductOrder/queryById/${id}`),
    // 确认单确认退详情---工程单
    orderDetailListPro: (id) => API.GET(`${BaseApi}/decorate/productOrder/queryById/${id}`),
    // 确认单确认退详情---工程单 审核
    orderDetailListProExamine: (params) => API.POST(`${BaseApi}/decorate/productOrder/deepExamine`, params),
    // 分包单详情
    pageDetail: (id) => API.GET(`${BaseApi}/decorate/sellDivide/queryById/${id}`),
    // 查询验收分包单详情
    imgDetail: (id) => API.GET(`${BaseApi}/decorate/sellDivideImg/queryById/${id}`),
    // 分包单审核
    careful: (params) => API.POST(`${BaseApi}/decorate/sellDivide/careful`, params),
    // 查询财务放款详情
    searchmsg: (id) => API.GET(`${BaseApi}/decorate/financeProjectAllocate/queryByIdAndOrderInfo/${id}`),
    // 确认放款
    financeLoan: (params) => API.POST(`${BaseApi}/decorate/financeProAll/loan`, params),
    // 安装完毕-下单--详情
    queryDetail: (id) => API.GET(`${BaseApi}/decorate/sellOrder/queryById?id=${id}`),
    // 安装完毕--表格
    queryTable: (id) => API.GET(`${BaseApi}/decorate/sellOrder/queryItemListBySellOrderId?id=${id}`),
    // 安装完毕--确认
    installed: (id) => API.GET(`${BaseApi}/decorate/sellOrder/finishOrder?id=${id}`),
    // 查询是否已经下单
    isPlaceOrder: (id) => API.GET(`${BaseApi}/decorate/messageLog/queryById/${id}`),
    // 确认下单
    subContact: (params) => API.POST(`${BaseApi}/decorate/sellOrder/placeOrder`, params),
    // 入库管理---自理销售
    AddWarehousing: (params) => API.POST(`${BaseApi}/decorate/sellProductOrder/stateByIds`, params),
    // 入库管理工程单
    orderDetailList1: (id) => API.GET(`${BaseApi}/decorate/productOrder/warehousing/${id}`),
    // 入库提交审核工程单
    AddWarehousing2: (params) => API.POST(`${BaseApi}/decorate/productOrder/addWarehousing`, params),
    // 收银通知
    Cashiernotice: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}/decorate/financePlan/pageList`, params)
    },
    // 确定缴费
    cashierPay: (params) => API.POST(`${BaseApi}/decorate/financeDetail/add`, params),
    // 查询缴费方式
    detailList: (params) => {
        buildParam(params)
        return API.POST(`${BaseApi}/decorate/financeDetail/list`, params)
    },

    //话务看板
    //线索数据
    clueData: (params) => API.POST(`${BaseApi}/report/hawBoard/dataStatistics`, params),
    //渠道线索占比
    channelClueData: (params) => API.POST(`${BaseApi}/report/hawBoard/channelRate`, params),
    //话务进线排行榜
    inboundLeaderboard: (params) => API.POST(`${BaseApi}/report/hawBoard/telephoneTop`, params),
    //线索进线走势图市场
    clueTrandMarket: (params) => API.POST(`${BaseApi}/report/hawBoard/clueValidRate`, params),
    //线索进线走势图折线
    clueTrandLine: (params) => API.POST(`${BaseApi}/report/hawBoard/clueValidChart`, params),
    //数据对比折线图
    contrastListHistogram: (params) => API.POST(`${BaseApi}/report/hawBoard/shopSignChart`, params),
    //数据对比排行榜
    contrastList: (params) => API.POST(`${BaseApi}/report/hawBoard/shopSignTop`, params),

  //话务看板
  //线索数据
  clueData: (params) => API.POST(`${BaseApi}/report/hawBoard/dataStatistics`, params),
  //渠道线索占比
  channelClueData: (params) => API.POST(`${BaseApi}/report/hawBoard/channelRate`, params),
  //话务进线排行榜
  inboundLeaderboard: (params) => API.POST(`${BaseApi}/report/hawBoard/telephoneTop`, params),
  //线索进线走势图市场
  clueTrandMarket: (params) => API.POST(`${BaseApi}/report/hawBoard/clueValidRate`, params),
  //线索进线走势图折线
  clueTrandLine: (params) => API.POST(`${BaseApi}/report/hawBoard/clueValidChart`, params),
  //数据对比折线图
  contrastListHistogram: (params) => API.POST(`${BaseApi}/report/hawBoard/shopSignChart`, params),
  //数据对比排行榜
  contrastList: (params) => API.POST(`${BaseApi}/report/hawBoard/shopSignTop`, params),



    //运营看板
    //年度产值
    productionYearValue: (date) => API.GET(`${BaseApi}/report/operatorBoard/marketAnalysis?date=` + date),
    //线索数据
    productClueData: (date) => API.GET(`${BaseApi}/report/operatorBoard/dataIndex?date=` + date),
    //签单手签单量排名
    signList: (date) => API.GET(`${BaseApi}/report/operatorBoard/signer?date=` + date),
    //签单手签单量排名
    signDetailsList: (params) => API.POST(`${BaseApi}/report/operatorBoard/signerDetail`, params),
    //设计师排名
    designList: (date) => API.GET(`${BaseApi}/report/operatorBoard/transfer?date=` + date),
    //设计师排名详细
    designDetailsList: (params) => API.POST(`${BaseApi}/report/operatorBoard/transferDetail`, params),
    //设计师资源排名详细
    designOrderDetailsList: (params) => API.POST(`${BaseApi}/report/operatorBoard/front/transferOrderDetail`, params),
    //销售明细
    salesParticular: (params) => API.POST(`${BaseApi}/report/operatorBoard/sale`, params),
    //产值数据及图表数据
    productData: (date) => API.GET(`${BaseApi}/report/operatorBoard/front/chart?date=` + date),

  // =========================================消息通知=================================
  // 缴费审核--详情
  supplierPage: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/financeDetail/examineList`, params)
  },
  // 缴费审核--通过未通过
  payExamine: (params) => API.POST(`${BaseApi}/decorate/financeDetail/examine`, params),
  // 确认单确认退详情接口---自理销售
  orderDetailList: (id) => API.GET(`${BaseApi}/decorate/sellProductOrder/queryById/${id}`),
  // 确认单确认退详情---工程单
  orderDetailListPro: (id) => API.GET(`${BaseApi}/decorate/productOrder/queryById/${id}`),
  // 确认单确认退详情---工程单 审核
  orderDetailListProExamine: (params) => API.POST(`${BaseApi}/decorate/productOrder/deepExamine`, params),
  // 分包单详情
  pageDetail: (id) => API.GET(`${BaseApi}/decorate/sellDivide/queryById/${id}`),
  // 查询验收分包单详情
  imgDetail: (id) => API.GET(`${BaseApi}/decorate/sellDivideImg/queryById/${id}`),
  // 分包单审核
  careful: (params) => API.POST(`${BaseApi}/decorate/sellDivide/careful`, params),
  // 查询财务放款详情
  searchmsg: (id) => API.GET(`${BaseApi}/decorate/financeProjectAllocate/queryByIdAndOrderInfo/${id}`),
  // 查询财务放款
  getAllocateTotal: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/financeProjectAllocateDetail/getAllocateTotal`, params)
  },
  // 确认放款
  financeLoan: (params) => API.POST(`${BaseApi}/decorate/financeProAll/loan`, params),
  // 安装完毕-下单--详情
  queryDetail: (id) => API.GET(`${BaseApi}/decorate/sellOrder/queryById?id=${id}`),
  // 安装完毕--表格
  queryTable: (id) => API.GET(`${BaseApi}/decorate/sellOrder/queryItemListBySellOrderId?id=${id}`),
  // 安装完毕--确认
  installed: (id) => API.GET(`${BaseApi}/decorate/sellOrder/finishOrder?id=${id}`),
  // 查询是否已经下单
  isPlaceOrder: (id) => API.GET(`${BaseApi}/decorate/messageLog/queryById/${id}`),
  // 确认下单
  subContact: (params) => API.POST(`${BaseApi}/decorate/sellOrder/placeOrder`, params),
  // 入库管理---自理销售
  AddWarehousing: (params) => API.POST(`${BaseApi}/decorate/sellProductOrder/stateByIds`, params),
  // 入库管理工程单
  orderDetailList1: (id) => API.GET(`${BaseApi}/decorate/productOrder/warehousing/${id}`),
  // 入库提交审核工程单
  AddWarehousing2: (params) => API.POST(`${BaseApi}/decorate/productOrder/addWarehousing`, params),
  // 收银通知
  Cashiernotice: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/financePlan/pageList`, params)
  },
  // 确定缴费
  cashierPay: (params) => API.POST(`${BaseApi}/decorate/financeDetail/add`, params),
  // 查询缴费方式
  detailList: (params) => {
    buildParam(params)
    return API.POST(`${BaseApi}/decorate/financeDetail/list`, params)
  },
  // 看板 -->查看用户权限
  getBoardAuthority: (id) => API.GET(`${SysApi}/backend/role/getRolesByUserID?id=${id}`),
  // 查询产品专员
  projuctCommissioner: (params) => API.POST(`${BaseApi}/decorate/orderInfo/r04`, params),
  // 查询产品详情
  queryProductSpecialist: (params) => API.POST(`${BaseApi}/decorate/budgetInfo/setProduct`, params),
  // 分配产品专员
  distributioncommissioner: (params) => API.POST(`${BaseApi}/decorate/orderInfo/c04`, params),
  // 查询选材单审核详情
  querySelectDetails: (id) => API.POST(`${BaseApi}/decorate/budgetInfo/r03/${id}`),
  // 查询预算单审核通过
  getBudgetInfo: (id) => API.GET(`${BaseApi}/decorate/orderInfo/orderDetails/${id}`),


  // =========================================广告费用看板=================================
  //获取模板列表
  getTemplateList: (params) => API.POST(`${BaseApi}/task/profitTemplate/list`, params),
  //获取模板信息
  getTemplateDetails: (id) => API.GET(`${BaseApi}/task/profitTemplate/queryById/${id}`),
  //添加模板
  addTemplate: (params) => API.POST(`${BaseApi}/task/profitTemplate/add`, params),
  //修改模板
  editTemplate: (params) => API.POST(`${BaseApi}/task/profitTemplate/edit`, params),
  //根据月份获取转单产值
  getConversionOutputValue: (params) => API.POST(`${BaseApi}/task/profitTask/getConversionOutputValue`, params),
  //获取任务列表
  getMissionList: (params) => API.POST(`${BaseApi}/task/profitTask/list`, params),
  //获取市场已设置月份
  getSetUpMonthByMarketCode: (params) => API.POST(`${BaseApi}/task/profitTask/getSetUpMonthByMarketCode`, params),
  //添加任务
  addMission: (params) => API.POST(`${BaseApi}/task/profitTask/add`, params),
  //编辑任务
  editMission: (params) => API.POST(`${BaseApi}/task/profitTask/updateProfitTaskById`, params),
  //获取任务详情
  getMissionDetails: (id) => API.GET(`${BaseApi}/task/profitTask/queryById/${id}`),
  //门店经营监控数据
  getBusinessData: (params) => API.POST(`${BaseApi}/task/profitTask/listQ`, params),
  //获取权限市场
  getMarkets: () => API.GET(`${BaseApi}/task/profitTask/userMarket`),
  //对比数据
  getComparisonData: (params) => API.POST(`${BaseApi}/task/profitTask/compareList`, params),
  //调整记录列表
  getAdjustMentRecord: (params) => API.POST(`${BaseApi}/task/profitTask/updateList`, params),
  //调整记录详情
  getRecordDetails: (params) => API.POST(`${BaseApi}/task/profitTask/updateDetail`, params),
  //调整任务详情
  getMissionAdjustment: (params) => API.POST(`${BaseApi}/task/profitTask/detail`, params),
  //调整任务
  missionAdjustment: (params) => API.POST(`${BaseApi}/task/profitTask/addUpdateTask`, params),
  //获取权限市场
  getAuthMarket: () => API.GET(`${BaseApi}/task/profitTemplate/getMarketByRole`),

}
